/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-03-22 15:17:52
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2023-08-08 17:29:50
 * @Description: 
 */
/*
 * 员工相关接口
 * */
import request from '@/utils/request'

/*
 * 获取员工列表
 * */
export function getEmployeeList(data) {
  return request({
    url: '/work/mini_user_bind/list',
    method: 'get',
    params: data,
  })
}

/*
 * 设置员工职位
 * */
export function setEmployeePosition(parameter) {
  return request({
    url: '/work/user/set_position',
    method: 'post',
    data: parameter,
  })
}

/*
 * 同步通讯录
 * */
export function sync() {
  return request({
    url: '/work/user/sync',
    method: 'get',
  })
}

/*
 * 电子名片码
 * */
export function getEcardCode(params) {
  return request({
    url: '/work/user/business_card',
    method: 'get',
    params,
  })
}