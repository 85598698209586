<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-03-22 15:17:52
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2023-03-29 14:16:14
 * @Description: 
-->
<template>
  <div
    class="text-center px-0.5 overflow-hidden overflow-ellipsis whitespace-nowrap seller"
  >
    <template v-if="info.with_corp_user">
      销售：
      <template v-if="info.with_corp_user && info.with_corp_user.name">
        {{ info.with_corp_user.name }}
      </template>
      <ww-open-data
        v-else
        type="userName"
        :openid="info.with_corp_user && info.with_corp_user.userid"
      />
    </template>
    <template v-else>
      <template
        v-if="info.with_mini_bind_user && info.with_mini_bind_user.nickname"
      >
        销售：{{ info.with_mini_bind_user.nickname }}
      </template>
      <template v-else-if="info.with_mini_user && info.with_mini_user.nickname">
        销售：{{ info.with_mini_user.nickname }}
      </template>
      <template v-else-if="info.with_a_map_order && info.with_a_map_order.id">
        销售：高德地图
      </template>
      <template v-else>前台预订</template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'boardInfo',
  props: {
    info: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  mounted() {
    // console.log(this.info);
  },
}
</script>

<style>
  .seller {
    position: relative;
    padding: 0 !important;
  }

  .seller::before {
    content: '';
    width: 1px;
    height: 16px;
    background: #e6e6e6;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
